export function renderTaxString(
  tax: number,
  priceIncludesTax: boolean
): string {
  if (tax > 0 && priceIncludesTax) {
    return " inkl. mva";
  } else if (tax > 0 && !priceIncludesTax) {
    return " + mva";
  }

  return "";
}
